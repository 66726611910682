import React from "react";
import { Button, Form, Input, Loader, Modal, Segment } from "semantic-ui-react";
import { getPermissionsLabel, PermissionLevels } from "../permissions";

interface IProperties {
    username: string;
    permissions: PermissionLevels;
    alwaysOpen?: boolean;
}

interface IState {

}

export class LoginMenu extends React.Component<IProperties, IState> {
    constructor(props: IProperties) {
        super(props);

        this.state = {
            
        };
    }

    public render(): JSX.Element {
        return (
            <div style={{ display: "table", textAlign: "right", color: "white", height: 36, margin: "auto", marginRight: 0 }}>
                <div style={{ verticalAlign: "middle", display: (this.props.alwaysOpen === true) ? "none": "table-cell", paddingRight: 10 }}>
                    { this.getLoginLabel() }
                </div>
                <Button icon={(this.props.permissions === PermissionLevels.Unauthorized) ? "sign-in" : "sign-out"} floated="right" circular style={{ display: (this.props.alwaysOpen === true) ? "none": "block" }} onClick={() => {
                    // this.setState({ modalOpen: true });
                    if (this.props.permissions === PermissionLevels.Unauthorized) {
                        window.location.replace("/auth/google");
                    } else {
                        window.location.replace("/auth/google/logout");
                    }
                }} />
            </div>
        );
    }

    private getLoginLabel(): JSX.Element {
        if (this.props.permissions === PermissionLevels.Unauthorized) {
            return <span>Login</span>;
        } else {
            return <span>Logged in as {this.props.username}<br/>{getPermissionsLabel(this.props.permissions!)}</span>;
        }
    }
}
